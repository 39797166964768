import { Link } from 'react-router-dom';

const Service = ({ time = '', text, serviceIcon, routeUrl, className = '' }) => {
  return (
    <div className={`service ${className}`}>
      <Link to={routeUrl}>
        <img className='service-img' src={serviceIcon}></img>
      </Link>
      <span className='service-text'>{text}</span>
      <span className='service-text'>{time}</span>
    </div>
  );
};

export default Service;
