import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import OkeepLogo from '../assets/images/okeep-logo.png';
import DentalCare from '../assets/dental/dental-care.png';
import ClinidentMP4 from '../assets/dental/clinident.mp4';
import clinidentLogo from '../assets/dental/nisantasiClinic/clinident-logo.png';
import instagramIcon from '../assets/dental/nisantasiClinic/instagramRed.svg';
import Nisantasi1 from '../assets/dental/nisantasiClinic/nisantasi1.jpeg';
import { CLINIDENT_INSTAGRAM_URL } from '../helpers/constants';
import { openInNewTab } from '../helpers/helpers';
import { Link } from 'react-router-dom';

/**
 * Preview Modal
 * @param {object} formData - data object to reference.
 * @param {object} initialValues - initial values of form.
 **/
const OpportunityModal = ({ openModal, closeModal }) => {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          closeModal();
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <div className='modal-container opportunity-modal'>
          <img className='modal-logo' src={OkeepLogo} alt='Header' />

          <div className='opportunity-modal-body'>
            <img className='header-opportunity-image' src={DentalCare} alt='Header' />
            <div className='opportunity-modal-container'>
              <video className='header-video' controls poster={Nisantasi1} loop>
                <source src={ClinidentMP4} type='video/mp4' />
              </video>
              <div className='opportunity-modal-text'>Okeep and Clinident Nişantaşı offer you</div>
              <div className='discount-message'>
                Free dental check up and %20 discount for Okeep guest !
              </div>
              <div className='opportunity-modal-text'>
                Just fill out the form. Pick a date and that’s it
              </div>
              <div className='clinic-button-container'>
                <Link to={'/dental-care'} className='confirm-btn' onClick={() => closeModal()}>
                  <span className='title'>Take a look</span>
                </Link>

                <img
                  onClick={() => openInNewTab(CLINIDENT_INSTAGRAM_URL)}
                  className='instagram-btn'
                  src={instagramIcon}
                  alt='Instagram Button'
                />
              </div>
              <img className='clinident-logo' src={clinidentLogo} alt='Clinident Logo' />
            </div>

            <IconButton className='close-btn' aria-label='close' onClick={() => closeModal()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OpportunityModal;
