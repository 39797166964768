import React, { useState, useEffect } from 'react';
import { DateTimePicker, Application, Input, CheckboxToggle } from 'react-rainbow-components';
import clinidentLogo from '../../assets/dental/nisantasiClinic/clinident-logo.png';
import { useRequestData } from '../../store/context';

const Form = () => {
  const theme = {
    rainbow: {
      palette: {
        brand: '#FFC42E',
      },
    },
  };
  const { setTimeString, setDateString, dateString, timeString, setDate } = useRequestData();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [dateSelected, setDateSelected] = useState(formData.dateSelected || false);
  const [name, setName] = useState(formData.name || '');
  const [phone, setPhone] = useState(formData.phone || '');
  const [checkedTransferService, setCheckedTransferService] = useState(
    formData.checkedTransferService || false,
  );

  const handleChange = (value) => {
    setDateString(value.toLocaleDateString('en-us', options));
    setTimeString(value.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
    setDate(value);
    setDateSelected(true);
    setFormData({ ...formData, dateSelected: true });
  };

  const handleNameChange = (e) => {
    setName(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      name: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setFormData({ ...formData, phone: e.target.value });
  };

  const handleToggleChange = (e) => {
    setCheckedTransferService(e.target.checked);
    setFormData({ ...formData, checkedTransferService: e.target.checked });
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className='form-container'>
      <div className='form-body'>
        <div className='form-title'>Free Dental Check Up</div>
        <Application theme={theme} className='rainbow-align-content_center form-date-picker'>
          <DateTimePicker
            value={dateSelected ? `${dateString} ${timeString} ` : null}
            placeholder='When do you want ?'
            onChange={handleChange}
            className='rainbow-m-around_small custom-date-picker'
            hour24
            isCentered={true}
            minDate={new Date()}
            icon={<i style={{ display: 'none' }} />}
            required={true}
          />
          <Input
            placeholder='Name & Surname'
            type='text'
            className='rainbow-m-around_small custom-date-picker'
            style={{ width: '100%' }}
            isCentered={true}
            value={name}
            onChange={handleNameChange}
            required={true}
          />
          <Input
            placeholder='Phone Number'
            type='number'
            className='rainbow-m-around_small custom-date-picker'
            style={{ width: '100%' }}
            isCentered={true}
            value={phone}
            onChange={handlePhoneChange}
            required={true}
            s
          />
        </Application>
        <div className='form-question-container'>
          <div className='form-question'>Do you want transfer service?</div>
          <CheckboxToggle
            className='form-checkbox'
            id='checkbox-toggle-component-1'
            value={checkedTransferService}
            onChange={handleToggleChange}
          />
        </div>
        <div className='form-information-box'>
          Round trip transfer fee just 10 euro The transfer is free if you start any treatment.
        </div>
      </div>

      <img className='clinident-logo' src={clinidentLogo} alt='Clinident Logo' />
    </div>
  );
};

export default Form;
