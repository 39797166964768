const DentalButton = ({ title, iconSrc, bgColor = '#1E1E1E;', buttonFunction }) => {
  return (
    <div className='dental-button' onClick={buttonFunction} style={{ backgroundColor: bgColor }}>
      <img className='button-icon' src={iconSrc} alt='Button' />
      <div className='button-text'>{title}</div>
    </div>
  );
};

export default DentalButton;
