import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import dentalCareIcon from '../../assets/dental/dental-care.png';
import DentalCheckUpBanner from '../../assets/dental/nisantasiClinicBanners/FreeDentalBanner.jpeg';
import HollywoodSmileBanner from '../../assets/dental/nisantasiClinicBanners/HollywoodSmileBanner.jpeg';
import ImplantTreatmentsBanner from '../../assets/dental/nisantasiClinicBanners/ImplantTreatmentsBanner.jpeg';
import PorcelainLaminateBanner from '../../assets/dental/nisantasiClinicBanners/PorcelainLaminateBanner.jpeg';
import ZirconiumCrownsBanner from '../../assets/dental/nisantasiClinicBanners/ZirconiumCrownsBanner.jpeg';
import TeethBleachingBanner from '../../assets/dental/nisantasiClinicBanners/TeethBleachingBanner.jpeg';
import dentalCheckUp from '../../assets/dental/dental-checkup.png';
import ImplantTreatments from '../../assets/dental/implant-treatments.png';
import PorcelainLaminate from '../../assets/dental/porcelain-laminate.png';
import ZirconiumCrowns from '../../assets/dental/zirconium-crowns.png';
import TeethBleaching from '../../assets/dental/teeth-bleaching.png';
import HollywoodSmile from '../../assets/dental/hollywood-smile.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import Warning from '../../components/warning';
import DentalButton from '../../components/dentalButton';
import InfoModal from '../../components/infoModal';
import NextPageButton from '../../components/nextPageButton';
import DentalCheckUpText from './DentalCheckUpText';
import HollywoodSmileText from './HollywoodSmileText';
import ImplantTreatmentsText from './ImplantTreatmentsText';
import PorcelainLaminateText from './PorcelainLaminateText';
import ZirconiumCrownsText from './ZirconiumCrownsText';
import TeethBleachingText from './TeethBleachingText';
import OpportunityModal from '../../components/opportunityModal';

const DentalCare = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const [showDentalCheckUp, setShowDentalCheckUp] = useState(false);
  const [showHollywoodSmile, setShowHollywoodSmile] = useState(false);
  const [showImplantTreatments, setShowImplantTreatments] = useState(false);
  const [showPorcelainLaminate, setShowPorcelainLaminate] = useState(false);
  const [showZirconiumCrowns, setShowZirconiumCrowns] = useState(false);
  const [showTeethBleaching, setShowTeethBleaching] = useState(false);
  const [showDentalCareModal, setShowDentalCareModal] = useState(false);
  const homeRoute = localStorage.getItem('HomeRoute');

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem('ShowDentalCareModal')) {
        return;
      }
      setShowDentalCareModal(true);
    }, 3000);
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('ShowDentalCareModal', false);
    setShowDentalCareModal(false);
  };

  return (
    <div className='main-container'>
      <Header title='Dental Care' iconSrc={dentalCareIcon} />
      <div className='content'>
        <div className='content-container bg-dental'>
          <div className='content-header'>
            <Link to={`/${homeRoute}`}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>Procedure</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body procedures-content-body'>
            <DentalButton
              title='Free Dental Check-Up'
              iconSrc={dentalCheckUp}
              bgColor='#FFBB21'
              buttonFunction={() => setShowDentalCheckUp(true)}
            />
            <DentalButton
              title='Implant Treatments'
              iconSrc={ImplantTreatments}
              buttonFunction={() => setShowImplantTreatments(true)}
            />
            <DentalButton
              title='Zirconium Crowns'
              iconSrc={ZirconiumCrowns}
              buttonFunction={() => setShowZirconiumCrowns(true)}
            />
            <DentalButton
              title='Hollywood Smile'
              iconSrc={HollywoodSmile}
              buttonFunction={() => setShowHollywoodSmile(true)}
            />
            <DentalButton
              title='Teeth Bleaching'
              iconSrc={TeethBleaching}
              buttonFunction={() => setShowTeethBleaching(true)}
            />
            <DentalButton
              title='Porcelain Laminate'
              iconSrc={PorcelainLaminate}
              buttonFunction={() => setShowPorcelainLaminate(true)}
            />
          </div>
        </div>
      </div>
      <NextPageButton
        buttonText='Go to Dental Clinic'
        bgColor='#FF4954'
        routeUrl={'/dental-clinic'}
      />
      <InfoModal
        title='Free Dental Check Up'
        imageSrc={DentalCheckUpBanner}
        openModal={showDentalCheckUp}
        closeModal={() => setShowDentalCheckUp(false)}
        textElement={<DentalCheckUpText />}
      />
      <InfoModal
        title='Hollywood Smile'
        imageSrc={HollywoodSmileBanner}
        openModal={showHollywoodSmile}
        closeModal={() => setShowHollywoodSmile(false)}
        textElement={<HollywoodSmileText />}
      />
      <InfoModal
        title='Implant Treatments'
        imageSrc={ImplantTreatmentsBanner}
        openModal={showImplantTreatments}
        closeModal={() => setShowImplantTreatments(false)}
        textElement={<ImplantTreatmentsText />}
      />
      <InfoModal
        title='Porcelain Laminate'
        imageSrc={PorcelainLaminateBanner}
        openModal={showPorcelainLaminate}
        closeModal={() => setShowPorcelainLaminate(false)}
        textElement={<PorcelainLaminateText />}
      />
      <InfoModal
        title='Zirconium Crowns'
        imageSrc={ZirconiumCrownsBanner}
        openModal={showZirconiumCrowns}
        closeModal={() => setShowZirconiumCrowns(false)}
        textElement={<ZirconiumCrownsText />}
      />
      <InfoModal
        title='Teeth Bleaching'
        imageSrc={TeethBleachingBanner}
        openModal={showTeethBleaching}
        closeModal={() => setShowTeethBleaching(false)}
        textElement={<TeethBleachingText />}
      />
      <OpportunityModal
        title='Hollywood Smile'
        imageSrc={HollywoodSmileBanner}
        openModal={showDentalCareModal}
        closeModal={() => handleCloseModal()}
        textElement={<HollywoodSmileText />}
      />
    </div>
  );
};

export default DentalCare;
