const DentalCheckUpText = () => {
  return (
    <>
      <p>Clinident has a special collaboration with okeep.</p>
      <p>
        Special offer for our guest
        <b> free dental checkups, consultation</b> and <b>dental x-ray.</b>
      </p>
      <p> We only charge for the treatments rendered and do not charge for the dental checkup.</p>
    </>
  );
};

export default DentalCheckUpText;
