/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useRequestData } from '../store/context';

const DentalRequestMessage = ({ setMessage }) => {
  const { timeString, dateString } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));
  const homeName = home ? home.title : '';
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const guestName = formData ? formData.name : '';
  const phoneNumber = formData ? formData.phone : '';
  const transferService = formData ? formData.checkedTransferService : false;
  const transferServiceElement = (
    <>
      and <span>I want Transfer Service</span>
    </>
  );
  const message = `Hello Okeep, I'm ${guestName}, Your guest from ${homeName}. I request a Free Dental Check Up ${
    transferService && 'and I want Transfer Service'
  } I'll go to ${timeString} on ${dateString}. ${
    phoneNumber && `My phone number is "${phoneNumber}"`
  }`;
  setMessage(message);

  return (
    <div className='request-container'>
      <div className='info-title info'>Hello Okeep,</div>
      <div className='info-request info'>
        I'm <span>{guestName}</span>
      </div>
      <div className='info-house info'>
        Your guest from <span>{homeName}</span>
      </div>
      <div className='info-request info'>
        I request a Free Dental Check Up {transferService && transferServiceElement}
      </div>
      <div className='info-date info'>
        I'll go to <span>{timeString}</span> on <span>{dateString}</span>
      </div>
      {phoneNumber && (
        <div className='info-date info'>
          My phone number is <span>"{phoneNumber}"</span>
        </div>
      )}
    </div>
  );
};

export default DentalRequestMessage;
