import React from 'react';
import logo from '../assets/images/okeep-logo.png';

const Warning = ({ text = 'Apologize, We are unable to offer our services to you.' }) => {
  return (
    <div className='main-container'>
      <div className='header'>
        <img className='okeep-logo' src={logo} alt='Logo' />
      </div>
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header center'>
            <div className='content-title'>Message</div>
          </div>
          <div className='content-body warning'>
            <div className='description'>{text}</div>
            <div className='description'>Please Re-scan the QR code from your home</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warning;
