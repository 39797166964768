import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import house from '../../assets/images/house.png';
import Warning from '../../components/warning';

const WelcomeGuest = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const homeName = home ? home.title : '';
  const homeImage = home.image;
  const homeAddress = home.address || 'Address not entered yet';
  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <div className='main-container'>
      <Header title='Welcome Guest!' />
      <div className='content'>
        <div className='content-container bg-yellow'>
          <div className='content-header'>
            <Link className='hidden'>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>{homeName}</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <img className='house' src={house} alt='Icon' />
            <img className='guest' src={homeImage} alt='Icon' />
            <div className='content-text'>Adress : {homeAddress}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeGuest;
