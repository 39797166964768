const ButtonWithIcon = ({ title, iconSrc, sendMessage, className = '' }) => {
  return (
    <div className={`button ${className}`} onClick={sendMessage}>
      <img className='button-icon' src={iconSrc} alt='Button' />
      <div className='button-text'>{title}</div>
    </div>
  );
};

export default ButtonWithIcon;
