const ZirconiumCrownsText = () => {
  return (
    <>
      <p>
        Enjoy your smile with monolithic zirconium crowns that are produced untouched by our new
        generation devices.
      </p>
      <p>Our treatments are guaranteed for 10 years.</p>
      <p>
        You can get a price quote from us with <b>free dental check-up</b> service by coming to our
        clinic.
      </p>
      <p>We issue a certificate and guarantee that the treatment is valid for 10 years.</p>
    </>
  );
};

export default ZirconiumCrownsText;
