import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import transportationIcon from '../../assets/images/transportation-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import DatePicker from '../../components/datePicker';
import { useRequestData } from '../../store/context';
import Warning from '../../components/warning';

const SelectDate = () => {
  const { setRequestDesc } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  useEffect(() => {
    setRequestDesc('I request a vip taxi service for ');
    localStorage.setItem('RequestRoute', '/select-date');
  }, []);

  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <div className='main-container'>
      <Header title='Transportation' iconSrc={transportationIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to={'/vip-taxi'}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>VIP Taxi</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <DatePicker question='When do you want?' pickerText='I want' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDate;
