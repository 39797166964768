const InfoBottomContainer = ({
  mainTitle,
  firstTitle = 'Istanbul Airport',
  secondTitle = 'Istanbul Sabiha Gokcen',
  secondDisabled = false,
  firstDescOfFirstTitle,
  secondDescOfFirstTitle,
  firstDescOfSecondTitle,
  secondDescOfSecondTitle,
  style = { height: '80px' },
}) => {
  return (
    <div className='info-bottom-container'>
      <div className='info-bottom-container-title'>{mainTitle}</div>
      <div className='info-bottom-container-box' style={style}>
        <div className='info-container'>
          <div className='info-title'>{firstTitle}</div>
          <div className='info-desc'>
            <div className='first-desc'>{firstDescOfFirstTitle}</div>
            <div className='second-desc'> {secondDescOfFirstTitle}</div>
          </div>
        </div>
        {!secondDisabled && (
          <div className='info-container'>
            <div className='info-title'>{secondTitle}</div>
            <div className='info-desc'>
              <div className='first-desc'>{firstDescOfSecondTitle}</div>
              <div className='second-desc'>{secondDescOfSecondTitle}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBottomContainer;
