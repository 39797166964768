import { Link } from 'react-router-dom';
import nextIcon from '../assets/images/next-icon.png';

const nextPageButton = ({ buttonIcon = nextIcon, buttonText = 'Continue', routeUrl, bgColor }) => {
  return (
    <Link className='navigator-button' to={routeUrl} style={{ backgroundColor: bgColor }}>
      <div className='button-text-container'>
        <div className='button-text'>{buttonText}</div>
      </div>
      <img className='button-icon' src={buttonIcon} alt='Button' />
    </Link>
  );
};

export default nextPageButton;
