import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import nearbyIcon from '../../assets/images/nearby-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import whatsappIcon from '../../assets/images/whatsapp-icon.png';
import ButtonWithIcon from '../../components/buttonWithIcon';
import { NEARBY_API_URL } from '../../helpers/constants';
import { openInNewTab } from '../../helpers/helpers';
import Warning from '../../components/warning';
import pharmacyIcon from '../../assets/images/pharmacy.png';
import hospitalIcon from '../../assets/images/hospital.png';
import vetIcon from '../../assets/images/vet.png';
import marketIcon from '../../assets/images/market.png';
import taxiIcon from '../../assets/images/taxi.png';

const Nearby = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const sendMessage = () => {
    openInNewTab(NEARBY_API_URL);
  };

  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <div className='main-container'>
      <Header title='Nearby' iconSrc={nearbyIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to={`/${homeRoute}`}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>Nearby</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body' style={{ justifyContent: 'space-between' }}>
            <div className='description'>
              You can view the closest places to your current location by sending your location via
              Whatsapp and Telegram.
            </div>
            <div className='divider' style={{ width: '100%' }} />
            <div className='nearby-services'>
              <div className='nearby-service'>
                <img className='nearby-service-icon' src={pharmacyIcon} />
                <div className='nearby-service-title'>Pharmacy</div>
              </div>
              <div className='nearby-service'>
                <img className='nearby-service-icon' src={hospitalIcon} />
                <div className='nearby-service-title'>Hospital</div>
              </div>
              <div className='nearby-service'>
                <img className='nearby-service-icon' src={vetIcon} />
                <div className='nearby-service-title'>Vet</div>
              </div>
              <div className='nearby-service'>
                <img className='nearby-service-icon' src={marketIcon} />
                <div className='nearby-service-title'>Market</div>
              </div>
              <div className='nearby-service'>
                <img className='nearby-service-icon' src={taxiIcon} />
                <div className='nearby-service-title'>Taxi Station</div>
              </div>
            </div>
            <span className='title'>Send Location With</span>
            <ButtonWithIcon title='WhatsApp' iconSrc={whatsappIcon} sendMessage={sendMessage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nearby;
