const ImplantTreatmentsText = () => {
  return (
    <>
      <p>We work with dentists who are experts in their field.</p>
      <p>
        We only work with implant companies that promise the <b>highest quality</b> and{' '}
        <b>glongevity. </b>
        (Astra, Straummaun, Bego, Nobel, Ostan etc.)
      </p>
      <p>Our treatments are guaranteed for 10 years. We provide warranty certificate.</p>
      <p>
        You can get a price quote from us with <b>free dental check-up </b>
        service by coming to our clinic.
      </p>
    </>
  );
};

export default ImplantTreatmentsText;
