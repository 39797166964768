/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useRequestData } from '../store/context';

const HomeCleaningRequestMessage = ({ setMessage }) => {
  const { timeString, dateString } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));
  const homeName = home ? home.title : '';
  const paymentMethod = 'Cash';
  const message = `Hello Okeep, Your guest from ${homeName}. I request a Home Cleaning Service. I want to get the cleaning service at ${timeString} on ${dateString}.`;
  setMessage(message);

  return (
    <div className='request-container'>
      <div className='info-title info'>Hello Okeep,</div>
      <div className='info-house info'>
        Your guest from <span>{homeName}</span>
      </div>
      <div className='info-request info'>I request a Home Cleaning Service</div>
      <div className='info-date info'>
        I want to get the cleaning service at <span>{timeString}</span> on <span>{dateString}</span>
      </div>
      <div className='info-payment info'>
        I will pay with <span>{paymentMethod}</span>
      </div>
    </div>
  );
};

export default HomeCleaningRequestMessage;
