import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './assets/scss/style.scss';
import './assets/scss/components.scss';
import SessionControl from './pages/sessionControl/SessionControl';
import IncomingSessionControl from './pages/incomingSessionControl/IncomingSessionControl';
import WelcomeGuest from './pages/welcomeGuest/WelcomeGuest';
import CallHost from './pages/callHost/CallHost';
import Nearby from './pages/nearby/Nearby';
import UsefulInfo from './pages/usefulInfo/UsefulInfo';
import TransportationAirport from './pages/transportationAirport/TransportationAirport';
import AirportIST from './pages/airportIST/AirportIST';
import AirportSAW from './pages/airportSAW/AirportSAW';
import SendRequest from './pages/sendRequest/SendRequest';
import Provider from './components/Provider';
import Home from './pages/home/Home';
import SelectDate from './pages/selectDate/SelectDate';
import IncomingGuest from './pages/incomingGuest/IncomingGuest';
import ArrivalAirport from './pages/arrivalAirport/ArrivalAirport';
import ArriveIST from './pages/arriveIST/ArriveIST';
import ArriveSAW from './pages/arriveSAW/ArriveSAW';
import DentalCare from './pages/dentalCare/DentalCare';
import DentalClinic from './pages/dentalClinic/DentalClinic';
import DentalForm from './pages/dentalForm/DentalForm';
import HomeCleaning from './pages/homeCleaning/HomeCleaning';

function App() {
  return (
    <Provider className='App'>
      <Routes>
        <Route path='/:id' element={<SessionControl />} />
        <Route path='/incoming-guest/:id' element={<IncomingSessionControl />} />
        <Route path='/' element={<Home />} />
        <Route path='/welcome-guest' element={<WelcomeGuest />} />
        <Route path='/call-host' element={<CallHost />} />
        <Route path='/nearby' element={<Nearby />} />
        <Route path='/useful-information' element={<UsefulInfo />} />
        <Route path='/transportation-airport' element={<TransportationAirport />} />
        <Route path='/airport-ist' element={<AirportIST />} />
        <Route path='/airport-saw' element={<AirportSAW />} />
        <Route path='/send-request' element={<SendRequest />} />
        <Route path='/select-date' element={<SelectDate />} />
        <Route path='/incoming-guest' element={<IncomingGuest />} />
        <Route path='/arrival-airport' element={<ArrivalAirport />} />
        <Route path='/arrive-ist' element={<ArriveIST />} />
        <Route path='/arrive-saw' element={<ArriveSAW />} />
        <Route path='/dental-care' element={<DentalCare />} />
        <Route path='/dental-clinic' element={<DentalClinic />} />
        <Route path='/dental-form' element={<DentalForm />} />
        <Route path='/home-cleaning' element={<HomeCleaning />} />
      </Routes>
    </Provider>
  );
}

export default App;
