import { TELEGRAM_API_URL, WHATSAPP_API_URL, CLINIDENT_WHATSAPP_API_URL } from './constants';
import { sendTelegramMessage } from '../service/api';
import moment from 'moment';

export const openInNewTab = (url) => {
  window.location.href = url;
};

export const whatsappActions = (message) => {
  openInNewTab(WHATSAPP_API_URL + message);
};

export const whatsappClinidentActions = (message) => {
  openInNewTab(CLINIDENT_WHATSAPP_API_URL + message);
};

export const telegramActions = (message) => {
  sendTelegramMessage(message);
  openInNewTab(TELEGRAM_API_URL);
};

export const isSessionExpired = (sessionDate) => {
  const currentDate = moment().format('DD.MM.YYYY hh:mm');
  const date1 = moment(sessionDate, 'DD.MM.YYYY hh:mm');
  const date2 = moment(currentDate, 'DD.MM.YYYY hh:mm');
  const diff = date2.diff(date1, 'hours');
  if (diff < 48) {
    return false;
  }
  return true;
};
