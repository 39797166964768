const TeethBleachingText = () => {
  return (
    <>
      <p>
        Achieve the whiteness you dream of in just <b>40 minutes.</b>
      </p>
      <p>Our treatments are guaranteed for 10 years.</p>
      <p>
        You can get a price quote from us with <b>free dental check-up</b> service by coming to our
        clinic.
      </p>
      <p>We issue a certificate and guarantee that the treatment is valid for 10 years.</p>
    </>
  );
};

export default TeethBleachingText;
