import { useState, useEffect } from 'react';
import { MainContext } from '../store/context';
import moment from 'moment';
import { getExchangeRate } from '../service/api';

// eslint-disable-next-line no-mixed-operators
const timestamp = new Date().getTime(); // This would be the timestamp you want to format

const Provider = ({ children }) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const [counter, setCounter] = useState(0);
  const [date, setDate] = useState(moment().format('DD.MM.YYYY hh:mm'));
  const [timeString, setTimeString] = useState(
    new Date(timestamp).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }),
  );
  const [dateString, setDateString] = useState(
    new Date(timestamp).toLocaleDateString('en-us', options),
  );
  const [request, setRequest] = useState('Istanbul Airport');
  const [requestDesc, setRequestDesc] = useState('I request a transfer service to go to ');
  const [exchangeRate, setExchangeRate] = useState(0);

  const setExchange = async () => {
    const response = await getExchangeRate();
    setExchangeRate(response.data.result.toFixed(2));
  };

  useEffect(() => {
    setExchange();
    setInterval(() => {
      setExchange();
    }, 30000);
  }, []);

  const data = {
    request,
    requestDesc,
    date,
    timeString,
    dateString,
    exchangeRate,
    counter,
    setRequest,
    setRequestDesc,
    setTimeString,
    setDateString,
    setDate,
    setExchange,
    setCounter,
  };

  return (
    <MainContext.Provider value={data} className='App'>
      {children}
    </MainContext.Provider>
  );
};

export default Provider;
