import React from 'react';
import { DateTimePicker, Application } from 'react-rainbow-components';
import { useRequestData } from '../store/context';

const theme = {
  rainbow: {
    palette: {
      brand: '#FFC42E',
    },
  },
};

const DatePicker = ({ question, incomming = true, cleaning = true }) => {
  const { setTimeString, setDateString, dateString, timeString, setDate } = useRequestData();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const handleChange = (value) => {
    setDateString(value.toLocaleDateString('en-us', options));
    setTimeString(value.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
    setDate(value);
  };

  return (
    <div className='date-picker-container'>
      <Application theme={theme} className='rainbow-align-content_center'>
        <DateTimePicker
          label={question}
          value={`${dateString} ${timeString}`}
          onChange={handleChange}
          className='rainbow-m-around_small custom-date-picker'
          hour24
          isCentered={true}
          minDate={new Date()}
        />
      </Application>

      {!incomming && (
        <>
          <div className='arrival-info'>
            Estimated arrival time is <b>1 hour.</b>{' '}
          </div>
          <div className='arrival-info'>
            {' '}
            We recommend that you choose <b>min 3 hours</b> before the flight.
          </div>
        </>
      )}

      {!cleaning && (
        <div className='arrival-info'>You must send us your cleaning request 1 day before.</div>
      )}
    </div>
  );
};

export default DatePicker;
