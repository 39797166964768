import { Link } from 'react-router-dom';

const Airport = ({ title, text, routeUrl }) => {
  return (
    <Link className='airport' to={routeUrl}>
      <div className='airport-title'>{title}</div>
      <div className='airport-text'>{text}</div>
    </Link>
  );
};

export default Airport;
