import React from 'react';
import Header from '../../components/header';
import transportationIcon from '../../assets/images/transportation-icon.png';
import { isSessionExpired } from '../../helpers/helpers';
import Warning from '../../components/warning';
import NextPageButton from '../../components/nextPageButton';
import VehicleRules from '../../components/vehicleRules';
import InfoBottomContainer from '../../components/infoBottomContainer';
import { useRequestData } from '../../store/context';

const IncomingGuest = () => {
  const validHome = localStorage.getItem('ValidHome');
  const showSessionExpired = localStorage.getItem('showSessionExpired');

  if (!validHome && !showSessionExpired) {
    return <Warning></Warning>;
  }

  if (showSessionExpired) {
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const sessionDate = localStorage.getItem('SessionDate');
  const sessionExpired = isSessionExpired(sessionDate);

  if (sessionExpired) {
    localStorage.clear();
    localStorage.setItem('showSessionExpired', true);
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const { exchangeRate } = useRequestData();
  const sawPrice = 35;
  const istPrice = 30;

  return (
    <div className='main-container'>
      <Header title='Transportation' iconSrc={transportationIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header' style={{ background: 'none' }}>
            <img className='back-button hidden' />
            <div className='content-title'>Airport Transfer</div>
            <img className='home-button hidden' />
          </div>
          <div className='content-body'>
            <div className='request-container'>
              <div className='info-title info'>Hello Guest,</div>
              <div className='info-house info'>Most welcome to Istanbul!</div>
              <div className='info-date info'>
                We offer best and easiest VIP transportation solution for to go to your home
              </div>
            </div>
            <VehicleRules />
          </div>
          <InfoBottomContainer
            mainTitle='One way transfer fee'
            firstDescOfFirstTitle={`${istPrice} USD`}
            secondDescOfFirstTitle={`${(istPrice * exchangeRate).toFixed(2)} TRY`}
            firstDescOfSecondTitle={`${sawPrice} USD`}
            secondDescOfSecondTitle={`${(sawPrice * exchangeRate).toFixed(2)} TRY`}
          />
        </div>
      </div>
      <NextPageButton buttonText='Choose Airport' routeUrl={'/arrival-airport'} />
    </div>
  );
};

export default IncomingGuest;
