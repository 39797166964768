export const TELEGRAM_API_URL = 'https://t.me/a43a593843164bcf8d22e8b8040e';
export const WHATSAPP_API_URL = 'https://api.whatsapp.com/send/?phone=905367724858&text=';
export const TELEGRAM_BOT_URL =
  'https://api.telegram.org/bot5479857834:AAHe2zSfDNodYYxHNnSXX7K1OkpwLZfaH1k/sendMessage?chat_id=@a43a593843164bcf8d22e8b8040e&text=';
export const EXCHANCE_URL = 'https://api.exchangerate.host/convert?from=USD&to=TRY';
export const NEARBY_API_URL = 'https://api.whatsapp.com/send/?phone=908503086278&text=Hello';
export const INSTAGRAM_URL = 'https://www.instagram.com/okeepco/';
export const CLINIDENT_INSTAGRAM_URL = 'https://www.instagram.com/clinident_nisantasi/';
export const CLINIDENT_WHATSAPP_API_URL = 'https://api.whatsapp.com/send/?phone=905433296292&text=';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCOY6W6ec7Gkl5qtYmIimnKtLtQxAZGNes';
export const CLINIC_LOCATION_URL =
  'https://www.google.com/maps/place/Clinident+Ni%C5%9Fanta%C5%9F%C4%B1/@41.0480404,28.9910752,17z/data=!4m12!1m6!3m5!1s0x14cab70c817b0f03:0xc49e48649bd81d93!2zQ2xpbmlkZW50IE5pxZ9hbnRhxZ_EsQ!8m2!3d41.0480364!4d28.9932639!3m4!1s0x14cab70c817b0f03:0xc49e48649bd81d93!8m2!3d41.0480364!4d28.9932639?hl=en-US';
