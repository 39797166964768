import React from 'react';
import closeIcon from '../../assets/images/close-button.png';
import { GOOGLE_MAPS_API_KEY, CLINIC_LOCATION_URL } from '../../helpers/constants';
import { MapMarker, GMap } from 'react-rainbow-components';
// import openMap from 'react-native-open-maps';

const LocationInfo = ({ setActiveMap }) => {
  return (
    <div className='content-body location-body'>
      <div
        className='map-container'
        style={{
          height: '100%',
          width: '100%',
          marginBottom: '10px',
          marginTop: '15px',
          color: 'black',
        }}>
        <GMap
          apiKey={GOOGLE_MAPS_API_KEY}
          zoom={14}
          latitude={41.048173930425236}
          longitude={28.99325316935724}>
          <MapMarker
            latitude={41.048173930425236}
            longitude={28.99325316935724}
            style={{ display: 'none' }}
            className='rainbow-google-map_marker hidden'
            description='Teşvikiye, Abdi İpekçi Cd. Şişli Floor: 2, No: 59'
          />
        </GMap>
      </div>
      <div className='clinic-button-container' style={{ width: '100%', marginBottom: '10px' }}>
        <div
          style={{ backgroundColor: '#FFBB21' }}
          className='location-btn'
          onClick={() => (location.href = CLINIC_LOCATION_URL)}>
          <span className='title'>View on your map</span>
        </div>
        <img
          onClick={() => setActiveMap(false)}
          className='instagram-btn'
          src={closeIcon}
          alt='Instagram Button'
        />
      </div>
    </div>
  );
};

export default LocationInfo;
