import { Link } from 'react-router-dom';
import avatar from '../assets/images/avatar.png';
import ellipse from '../assets/images/ellipse.png';
import logo from '../assets/images/okeep-logo.png';

const Header = ({ title, iconSrc }) => {
  return (
    <>
      <div className='header'>
        <img className='okeep-logo' src={logo} alt='Logo' />
      </div>
      <Link className='user' to={'/welcome-guest'}>
        <div className='ellipse' style={{ backgroundImage: `url(${ellipse})` }}>
          <img className='avatar' src={avatar} alt='Avatar' />
        </div>
      </Link>
      <div className='title'>
        <div className='txt'>{title}</div>
        {iconSrc && <img className='icon' src={iconSrc} alt='Icon' />}
      </div>
    </>
  );
};

export default Header;
