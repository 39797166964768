import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import transportationIcon from '../../assets/images/transportation-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import Airport from '../../components/airport';
import Warning from '../../components/warning';
import InfoBottomContainer from '../../components/infoBottomContainer';

const ArrivalAirport = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <div className='main-container'>
      <Header title='Transportation' iconSrc={transportationIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to={`/${homeRoute}`}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>Airport Transfer</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <div className='question-airport'>Which airport will you use?</div>
            <div className='airport-container'>
              <Airport title='SAW' text='Istanbul Sabiha Gokcen' routeUrl={'/arrive-saw'} />
              <Airport title='IST' text='Istanbul Airport' routeUrl={'/arrive-ist'} />
            </div>
          </div>
          <InfoBottomContainer
            mainTitle='Estimated time for arrival to home'
            firstDescOfFirstTitle='45 min'
            firstDescOfSecondTitle='1 Hour'
          />
        </div>
      </div>
    </div>
  );
};

export default ArrivalAirport;
