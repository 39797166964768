import React from 'react';
import profileIcon from '../assets/images/profile.png';
import cancellationIcon from '../assets/images/cancellation.png';
import driverIcon from '../assets/images/driver.png';

const VehicleRules = () => {
  return (
    <div className='vehicle-rules-container'>
      <div className='vehicle-rules'>
        <div className='rule'>
          <img className='rule-img' src={profileIcon}></img>
          <div className='rule-text'>Max 6 Person</div>
        </div>
        <div className='rule'>
          <img className='rule-img' src={cancellationIcon}></img>
          <div className='rule-text-bold'>Free cancellation</div>
        </div>
      </div>
      <div className='vehicle-rules'>
        <div className='rule'>
          <img className='rule-img' src={driverIcon}></img>
          <div className='rule-text'>The driver will meet you on the arriving passenger side.</div>
        </div>
      </div>
    </div>
  );
};

export default VehicleRules;
