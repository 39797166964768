import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import nisantasi1 from '../../assets/dental/nisantasiClinic/nisantasi1.jpeg';
import nisantasi2 from '../../assets/dental/nisantasiClinic/nisantasi2.jpeg';
import nisantasi3 from '../../assets/dental/nisantasiClinic/nisantasi3.jpeg';
import { Slide } from 'react-slideshow-image';
import instagramIcon from '../../assets/dental/nisantasiClinic/instagram.png';
import { CLINIDENT_INSTAGRAM_URL } from '../../helpers/constants';
import { openInNewTab } from '../../helpers/helpers';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import WorkingHours from './WorkingHours';

const ClinicInfo = ({ setActiveMap }) => {
  const slideRef = React.createRef();
  const [hoursPage, setHoursPage] = useState(false);

  const properties = {
    duration: 4000,
    autoplay: true,
    transitionDuration: 500,
    arrows: false,
    infinite: true,
    easing: 'ease',
  };

  const slideImages = [
    {
      url: nisantasi1,
    },
    {
      url: nisantasi2,
    },
    {
      url: nisantasi3,
    },
  ];

  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <>
      {hoursPage ? (
        <WorkingHours setHoursPage={setHoursPage} />
      ) : (
        <>
          <div className='content-header'>
            <Link to={'/dental-care'}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title-airport'>
              <span className='title'>Clinident</span>
              <span className='description clickable' onClick={() => setHoursPage(true)}>
                click for working hours
              </span>
            </div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <div className='procedure-description scroll'>
              <div className='slide-container'>
                <Slide ref={slideRef} {...properties}>
                  {slideImages.map((each, index) => (
                    <div key={index} className='each-slide'>
                      <img className='lazy' src={each.url} alt></img>
                    </div>
                  ))}
                </Slide>
              </div>

              <div className='text-container'>
                <div className='header'>
                  We use the latest equipment and technology and have our own digital dental labs
                  above world standards.
                </div>
                <div className='body'>
                  As Clinident, we promise you the best treatment option in the shortest time
                  possible. We have selected the highest quality materials, regardless of cost, to
                  provide you with healthy, long-lasting aesthetic results while protecting your
                  dental health. One of the most important factors in dentistry is the longevity and
                  integrity of the treatment. After treatment, your designated Medical Advisor will
                  be available for support 24 hours a day, 7 days a week.
                </div>
              </div>
            </div>
            <div className='clinic-button-container'>
              <div className='location-btn' onClick={() => setActiveMap(true)}>
                <span className='title'>View on map</span>
                <span className='address'>Abdi Ipekci street , Nisantasi</span>
              </div>
              <img
                onClick={() => openInNewTab(CLINIDENT_INSTAGRAM_URL)}
                className='instagram-btn'
                src={instagramIcon}
                alt='Instagram Button'
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClinicInfo;
