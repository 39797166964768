import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Warning from '../../components/warning';
import { getHome } from '../../firebase';
import Loader from '../../components/loader';
import moment from 'moment';

const SessionControl = () => {
  const { id } = useParams();
  const [validHome, setValidHome] = useState(false);
  const [loaderActive, setLoaderActive] = useState(true);
  const activeHome = JSON.parse(localStorage.getItem('Home'));
  const sessionDate = moment().format('DD.MM.YYYY hh:mm');

  const setActiveHome = async () => {
    if (activeHome?.id === id) {
      setValidHome(true);
      setLoaderActive(false);
      return;
    }
    const data = await getHome(id);
    if (data) {
      localStorage.setItem('Home', JSON.stringify(data));
      setValidHome(true);
      setLoaderActive(false);
      return;
    }
    setValidHome(false);
    setLoaderActive(false);
  };

  useEffect(() => {
    setActiveHome();
    localStorage.setItem('HomeId', id);
    localStorage.setItem('HomeRoute', '');
    localStorage.setItem('SessionDate', sessionDate);
  }, []);

  if (loaderActive) {
    return (
      <div className='main-container'>
        <Loader />;
      </div>
    );
  }

  if (validHome) {
    localStorage.setItem('ValidHome', true);
    localStorage.removeItem('showSessionExpired');
    window.location.href = '/';
    return;
  }

  return <Warning></Warning>;
};

export default SessionControl;
