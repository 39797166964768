import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRequestData } from '../../store/context';
import Header from '../../components/header';
import homeCleaningIcon from '../../assets/images/home-cleaning-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import Warning from '../../components/warning';
import DatePicker from '../../components/datePicker';
import NextPageButton from '../../components/nextPageButton';
import InfoBottomContainer from '../../components/infoBottomContainer';

const requestType = {
  airportRequest: false,
  arrivalRequest: false,
  dentalRequest: false,
  homeCleaningRequest: true,
};

const HomeCleaning = () => {
  const { setRequest, setRequestDesc } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const homeRoute = localStorage.getItem('HomeRoute');
  const { exchangeRate } = useRequestData();
  const price = 30;

  useEffect(() => {
    setRequest('');
    setRequestDesc('I request a Home Cleaning Service ');
    localStorage.setItem('RequestRoute', '/home-cleaning');
    localStorage.setItem('RequestType', JSON.stringify(requestType));
    localStorage.setItem('SendRequestHeaderIcon', homeCleaningIcon);
    localStorage.setItem('SendRequestHeaderTitle', 'Home Cleaning');
  }, []);

  return (
    <div className='main-container'>
      <Header title='Home Cleaning' iconSrc={homeCleaningIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to={`/${homeRoute}`}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>Form</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <div className='cleaning-description'>
              With the cleaning services that provide good service in the field, we clean your house
              like the first day in 3-4 hours.
            </div>
            <DatePicker question='When do you want ?' cleaning={false} />
            {/* <div className='cleaning-info-container'>
              <div className='cleaning-info-title'>Fixed Price</div>
              <div className='cleaning-info-box'>
                <div className='title'>Cleaning Fee</div>
                <div className='price-container'>
                  <div className='price-usd'>{price} USD</div>
                  <div className='price-try'>{(price * exchangeRate).toFixed(2)} TRY</div>
                </div>
              </div>
             
            </div> */}
          </div>
          <InfoBottomContainer
            style={{ height: '60px' }}
            mainTitle='Fixed Price'
            firstTitle='Home Cleaning'
            firstDescOfFirstTitle={`${price} USD`}
            secondDescOfFirstTitle={`${(price * exchangeRate).toFixed(2)} TRY`}
            secondDisabled={true}
          />
        </div>
      </div>
      <NextPageButton routeUrl={'/send-request'} />
    </div>
  );
};

export default HomeCleaning;
