import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import callHostIcon from '../../assets/images/call-host-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import whatsappIcon from '../../assets/images/whatsapp-icon.png';
import telegramIcon from '../../assets/images/telegram-icon.png';
import phoneIcon from '../../assets/images/phone-icon.png';
import ButtonWithIcon from '../../components/buttonWithIcon';
import { whatsappActions, telegramActions } from '../../helpers/helpers';
import Warning from '../../components/warning';

const CallHost = () => {
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  const homeName = home ? home.title : '';
  const message = `Hello Okeep, Your guest from ${homeName}, I want to talk my host.`;
  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <div className='main-container'>
      <Header title='Call Host' iconSrc={callHostIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to={`/${homeRoute}`}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>Reach Us</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body' style={{ justifyContent: 'space-between' }}>
            <div>
              <div className='description' style={{ padding: '10px 0' }}>
                We provide you quick solutions to the needs of our guests.
              </div>
              <div className='description' style={{ padding: '10px 0' }}>
                You can contact the host via social media platforms.
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '5px' }}>
              <span className='title'>Reach With</span>
              <ButtonWithIcon
                title='WhatsApp'
                iconSrc={whatsappIcon}
                sendMessage={() => whatsappActions(message)}
              />
              <ButtonWithIcon
                title='Telegram'
                iconSrc={telegramIcon}
                sendMessage={() => telegramActions(message)}
                className='telegram-button'
              />
              <a className='phone-container' href='tel:+905367724858'>
                <ButtonWithIcon title='Phone Number' iconSrc={phoneIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallHost;
