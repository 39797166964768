const PorcelainLaminateText = () => {
  return (
    <>
      <p>
        We cover them in the most appropriate way to their original form without harming your teeth.
      </p>
      <p>Our treatments are guaranteed for 10 years.</p>
      <p>
        You can get a price quote from us with <b>free dental check-up</b> service by coming to our
        clinic.
      </p>
      <p>We issue a certificate and guarantee that the treatment is valid for 10 years.</p>
    </>
  );
};

export default PorcelainLaminateText;
