import axios from 'axios';
import { EXCHANCE_URL, TELEGRAM_BOT_URL } from '../helpers/constants';

export const getExchangeRate = async () => {
  return await axios.get(`${EXCHANCE_URL}`);
};

export const sendTelegramMessage = async (message) => {
  return await axios.get(`${TELEGRAM_BOT_URL}${message}`);
};
