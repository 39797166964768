const HollywoodSmileText = () => {
  return (
    <>
      <p>
        Our clinic, which has all the modern and technological infrastructure, promises you the
        <b> most beautiful smile in 4 days.</b>
      </p>
      <p>
        Our treatments are <b>guaranteed for 10 years.</b>
      </p>
      <p>We issue a certificate and guarantee that the treatment is valid for 10 years.</p>
      <p>
        You can get a price quote by sending us your photo without going to our clinic for a price
        quote. We create the best smile for you using 3d modeling programs. we can show you before
        applying.
      </p>
    </>
  );
};

export default HollywoodSmileText;
