import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTimePicker, Application, Input } from 'react-rainbow-components';
import { useRequestData } from '../../store/context';
import Warning from '../../components/warning';
import Header from '../../components/header';
import NextPageButton from '../../components/nextPageButton';
import transportationIcon from '../../assets/images/transportation-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import infoIcon from '../../assets/images/info.png';

const requestType = {
  airportRequest: false,
  arrivalRequest: true,
  dentalRequest: false,
  homeCleaningRequest: false,
};

const ArriveSAW = () => {
  const theme = {
    rainbow: {
      palette: {
        brand: '#FFC42E',
      },
    },
  };

  const { setRequest, setRequestDesc } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));
  const homeRoute = localStorage.getItem('HomeRoute');

  if (!home) {
    return <Warning></Warning>;
  }

  useEffect(() => {
    setRequest('Sabiha Gokcen Airport');
    setRequestDesc('I request a transfer service from ');
    localStorage.setItem('RequestRoute', '/arrive-saw/');
    localStorage.setItem('RequestType', JSON.stringify(requestType));
    localStorage.setItem('SendRequestHeaderIcon', transportationIcon);
    localStorage.setItem('SendRequestHeaderTitle', 'Transportation');
    localStorage.setItem('Airport', 'Istanbul Sabiha Gokcen');
  }, []);

  const { setTimeString, setDateString, dateString, timeString, setDate } = useRequestData();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('FormData')) || {});
  const [dateSelected, setDateSelected] = useState(formData.dateSelected || false);
  const [name, setName] = useState(formData.name || '');
  const [flightNumber, setFlightNumber] = useState(formData.flightNumber || '');

  const handleChange = (value) => {
    setDateString(value.toLocaleDateString('en-us', options));
    setTimeString(value.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
    setDate(value);
    setDateSelected(true);
    setFormData({ ...formData, dateSelected: true });
  };

  const handleNameChange = (e) => {
    setName(e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()));
    setFormData({
      ...formData,
      name: e.target.value.replace(/(?:^|\s|["'([{])+\S/g, (l) => l.toUpperCase()),
    });
  };

  const handleFlightNumberChange = (e) => {
    setFlightNumber(e.target.value.toUpperCase());
    setFormData({
      ...formData,
      flightNumber: e.target.value.toUpperCase(),
    });
  };

  useEffect(() => {
    localStorage.setItem('FormData', JSON.stringify(formData));
  }, [formData]);

  return (
    <div className='main-container'>
      <Header title='Transportation' iconSrc={transportationIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to='/arrival-airport'>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title-airport'>
              <span className='title'>SAW</span>
              <span className='description'>Sabiha Gokcen Airport</span>
            </div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <div className='form-container' style={{ height: 'auto', padding: '20px 0' }}>
              <div className='form-body'>
                <Application
                  theme={theme}
                  className='rainbow-align-content_center form-date-picker'>
                  <Input
                    placeholder='Name & Surname'
                    type='text'
                    className='rainbow-m-around_small custom-input'
                    style={{ width: '100%' }}
                    isCentered={true}
                    value={name}
                    onChange={handleNameChange}
                    required={true}
                  />
                  <DateTimePicker
                    value={dateSelected ? `${dateString} ${timeString} ` : null}
                    placeholder='When do you want ?'
                    onChange={handleChange}
                    className='rainbow-m-around_small custom-input'
                    hour24
                    isCentered={true}
                    minDate={new Date()}
                    icon={<i style={{ display: 'none' }} />}
                    required={true}
                  />
                  <Input
                    placeholder='Enter your flight number'
                    type='text'
                    className='rainbow-m-around_small custom-input'
                    style={{ width: '100%' }}
                    isCentered={true}
                    value={flightNumber}
                    onChange={handleFlightNumberChange}
                    required={true}
                  />
                </Application>
              </div>
            </div>
            <div className='more-information-container'>
              <div>
                <img className='info-icon' src={infoIcon} alt='Info Icon' />
                <span className='more-information-text'>E.g. “TK2412”, “PC2204”</span>
              </div>
              <div className='more-information-text'>
                {' '}
                {
                  "This helps us calculate your pick-up time and let's the driver track your arrival."
                }
              </div>
              <div className='more-information-text'>
                {' '}
                You can usually find this on a confirmation email from the company you booked with
                (E.g. “TK2412”, “PC2204”)
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextPageButton routeUrl={'/send-request'} />
    </div>
  );
};

export default ArriveSAW;
