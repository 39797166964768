/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useRequestData } from '../store/context';

const ArrivalRequestMessage = ({ setMessage }) => {
  const { timeString, dateString } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));
  const homeName = home ? home.title : '';
  const airport = localStorage.getItem('Airport');
  const formData = JSON.parse(localStorage.getItem('FormData'));
  const paymentMethod = 'Cash';
  const flightNumber = formData ? formData.flightNumber : '';
  const guestName = formData ? formData.name : '';

  const message = `Hello Okeep, I'm ${guestName}. Your guest from ${homeName}. I request a transfer service from ${airport}. I'll arrive at ${timeString} on ${dateString}. ${
    flightNumber && `My flight number is "${flightNumber}"`
  }`;
  setMessage(message);

  return (
    <div className='request-container'>
      <div className='info-title info'>Hello Okeep,</div>
      <div className='info-request info'>
        I'm <span>{guestName}</span>
      </div>
      <div className='info-house info'>
        Your guest from <span>{homeName}</span>
      </div>
      <div className='info-request info'>
        I request a transfer service from <span>{airport}</span>
      </div>
      <div className='info-date info'>
        I'll arrive at <span>{timeString}</span> on <span>{dateString}</span>
      </div>
      {flightNumber && (
        <div className='info-date info' style={{ marginTop: '15px' }}>
          My flight number is <span>"{flightNumber}"</span>
        </div>
      )}
      <div className='info-payment info'>
        I will pay with <span>{paymentMethod}</span>
      </div>
    </div>
  );
};

export default ArrivalRequestMessage;
