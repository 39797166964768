import React, { useEffect } from 'react';
import 'react-slideshow-image/dist/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import dentalCareIcon from '../../assets/dental/dental-care.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import { useRequestData } from '../../store/context';
import Warning from '../../components/warning';
import NextPageButton from '../../components/nextPageButton';
import Form from './Form';

const requestType = {
  airportRequest: false,
  arrivalRequest: false,
  dentalRequest: true,
  homeCleaningRequest: false,
};

const DentalForm = () => {
  const { setRequestDesc } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  useEffect(() => {
    setRequestDesc('I request a Free Dental Check Up ');
    localStorage.setItem('RequestType', JSON.stringify(requestType));
    localStorage.setItem('RequestRoute', '/dental-form');
    localStorage.setItem('SendRequestHeaderIcon', dentalCareIcon);
    localStorage.setItem('SendRequestHeaderTitle', 'Dental Care');
  }, []);

  const homeRoute = localStorage.getItem('HomeRoute');

  return (
    <div className='main-container'>
      <Header title='Dental Care' iconSrc={dentalCareIcon} />
      <div className='content'>
        <div className='content-container bg-dental'>
          <div className='content-header'>
            <Link to={'/dental-clinic'}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title'>Form</div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <Form />
          </div>
        </div>
      </div>
      <NextPageButton
        buttonText='Send Your Request'
        bgColor='#FF4954'
        routeUrl={'/send-request/'}
      />
    </div>
  );
};

export default DentalForm;
