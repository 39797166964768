import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRequestData } from '../../store/context';
import Warning from '../../components/warning';
import NextPageButton from '../../components/nextPageButton';
import Header from '../../components/header';
import transportationIcon from '../../assets/images/transportation-icon.png';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import DatePicker from '../../components/datePicker';
import VehicleRules from '../../components/vehicleRules';

const requestType = {
  airportRequest: true,
  arrivalRequest: false,
  dentalRequest: false,
  homeCleaningRequest: false,
};

const AirportIST = () => {
  const { setRequest, setRequestDesc } = useRequestData();
  const home = JSON.parse(localStorage.getItem('Home'));
  const homeRoute = localStorage.getItem('HomeRoute');

  if (!home) {
    return <Warning></Warning>;
  }

  useEffect(() => {
    setRequest('Istanbul Airport');
    setRequestDesc('I request a transfer service to go to ');
    localStorage.setItem('RequestRoute', '/airport-ist');
    localStorage.setItem('RequestType', JSON.stringify(requestType));
    localStorage.setItem('SendRequestHeaderIcon', transportationIcon);
    localStorage.setItem('SendRequestHeaderTitle', 'Transportation');
    localStorage.setItem('Airport', 'Istanbul Airport');
  }, []);

  return (
    <div className='main-container'>
      <Header title='Transportation' iconSrc={transportationIcon} />
      <div className='content'>
        <div className='content-container bg-blue'>
          <div className='content-header'>
            <Link to='/transportation-airport'>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title-airport'>
              <span className='title'>IST</span>
              <span className='description'>Istanbul Airport</span>
            </div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body'>
            <DatePicker question='When do you want to take the taxi?' incomming={false} />
            <VehicleRules />
          </div>
        </div>
      </div>
      <NextPageButton routeUrl={'/send-request'} />
    </div>
  );
};

export default AirportIST;
