import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import whatsappIcon from '../assets/images/whatsapp-icon.png';
import { whatsappClinidentActions } from '../helpers/helpers';
import ButtonWithIcon from '../components/buttonWithIcon';

/**
 * Preview Modal
 * @param {object} formData - data object to reference.
 * @param {object} initialValues - initial values of form.
 **/
const InfoModal = ({ title, imageSrc, openModal, closeModal, textElement }) => {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          closeModal();
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <div className='modal-container'>
          <div className='modal-header'>{title}</div>
          <img className='header-image' src={imageSrc} alt='Header' />
          <div className='modal-body'>
            <div className='header-text'>{textElement}</div>
          </div>
          <ButtonWithIcon
            title='Ask Questions 7/24 '
            iconSrc={whatsappIcon}
            sendMessage={() => whatsappClinidentActions('')}
          />

          <IconButton className='close-btn' aria-label='close' onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
    </div>
  );
};

export default InfoModal;
