import React from 'react';
import CloseIcon from '../../assets/images/close-button.png';

const WorkingHours = ({ setHoursPage }) => {
  return (
    <>
      <div className='content-header'>
        <div onClick={() => setHoursPage(false)} className='hidden' style={{ margin: '0' }}>
          <img className='home-button' src={CloseIcon} alt='Close Button' />
        </div>
        <div className='content-title'>Working Hours</div>
        <div onClick={() => setHoursPage(false)}>
          <img className='home-button' src={CloseIcon} alt='Close Button' />
        </div>
      </div>
      <div className='content-body'>
        <div className='working-hours-container'>
          <div className='working-hours'>
            <div className='working-hours-title'>Monday</div>
            <div className='working-hours-time'>09:00 - 19:00</div>
          </div>
          <div className='divider'></div>
          <div className='working-hours'>
            <div className='working-hours-title'>Tuesday</div>
            <div className='working-hours-time'>09:00 - 19:00</div>
          </div>
          <div className='divider'></div>
          <div className='working-hours'>
            <div className='working-hours-title'>Wednesday</div>
            <div className='working-hours-time'>09:00 - 19:00</div>
          </div>
          <div className='divider'></div>
          <div className='working-hours'>
            <div className='working-hours-title'>Thursday</div>
            <div className='working-hours-time'>09:00 - 19:00</div>
          </div>
          <div className='divider'></div>
          <div className='working-hours'>
            <div className='working-hours-title'>Friday</div>
            <div className='working-hours-time'>09:00 - 19:00</div>
          </div>
          <div className='divider'></div>
          <div className='working-hours'>
            <div className='working-hours-title'>Saturday</div>
            <div className='working-hours-time'>09:00 - 13:00</div>
          </div>
          <div className='divider'></div>
          <div className='working-hours'>
            <div className='working-hours-title'>Sunday</div>
            <div className='working-hours-time'>Closed</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkingHours;
