import React from 'react';
import airportTransferIcon from '../../assets/images/airport-transfer-icon.png';
// import usefulInfoIcon from '../../assets/images/useful-info-icon.png';
import callHostIcon from '../../assets/images/call-host-icon.png';
import nearbyIcon from '../../assets/images/nearby-icon.png';
import dentalCareIcon from '../../assets/dental/dental-care.png';
import cleaningIcon from '../../assets/images/home-cleaning-icon.png';
import Service from '../../components/service';
import Header from '../../components/header';
import { isSessionExpired } from '../../helpers/helpers';
import Warning from '../../components/warning';

const Home = () => {
  const validHome = localStorage.getItem('ValidHome');
  const showSessionExpired = localStorage.getItem('showSessionExpired');

  if (!validHome && !showSessionExpired) {
    return <Warning></Warning>;
  }

  if (showSessionExpired) {
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  const sessionDate = localStorage.getItem('SessionDate');
  const sessionExpired = isSessionExpired(sessionDate);

  if (sessionExpired) {
    localStorage.clear();
    localStorage.setItem('showSessionExpired', true);
    return <Warning text='Your 48 hours session has ended.'></Warning>;
  }

  return (
    <div className='main-container'>
      <Header title='Choose Services' iconSrc={false} />
      <div className='content'>
        <div className='home-page content-container bg-yellow justify-content-center'>
          <div className='service-group'>
            <Service
              text={'Airport Transfer'}
              serviceIcon={airportTransferIcon}
              routeUrl={'/transportation-airport'}
            />
            <Service text={'Dental Care'} serviceIcon={dentalCareIcon} routeUrl={'/dental-care'} />
          </div>
          <div className='service-group'>
            <Service text={'Call Host'} serviceIcon={callHostIcon} routeUrl={'/call-host'} />
            <Service text={'Nearby'} serviceIcon={nearbyIcon} routeUrl={'/nearby'} />
          </div>
          <div className='service-group'>
            <Service
              text={'Home Cleaning'}
              serviceIcon={cleaningIcon}
              routeUrl={'/home-cleaning'}
            />
          </div>

          {/* <Service
            text={'Useful Information'}
            serviceIcon={usefulInfoIcon}
            routeUrl={'/useful-information'}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
