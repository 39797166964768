import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import backIcon from '../../assets/images/back-icon.png';
import homeIcon from '../../assets/images/home-icon.png';
import whatsappIcon from '../../assets/images/whatsapp-icon.png';
import telegramIcon from '../../assets/images/telegram-icon.png';
import ButtonWithIcon from '../../components/buttonWithIcon';
import AirportRequestMessage from '../../components/airportRequestMessage';
import ArrivalRequestMessage from '../../components/arrivalRequestMessage';
import DentalRequestMessage from '../../components/dentalRequestMessage';
import HomeCleaningRequestMessage from '../../components/homeCleaningRequestMessage';
import { telegramActions, whatsappActions } from '../../helpers/helpers';
import Warning from '../../components/warning';

const SendRequest = () => {
  const home = JSON.parse(localStorage.getItem('Home'));
  const [message, setMessage] = useState('');

  if (!home) {
    return <Warning></Warning>;
  }

  const requestRoute = localStorage.getItem('RequestRoute');
  const homeRoute = localStorage.getItem('HomeRoute');
  const headerIcon = localStorage.getItem('SendRequestHeaderIcon');
  const headerTitle = localStorage.getItem('SendRequestHeaderTitle');
  const requestType = JSON.parse(localStorage.getItem('RequestType'));

  return (
    <div className='main-container'>
      <Header title={headerTitle} iconSrc={headerIcon} />

      <div className='content'>
        <div className='content-container bg-gray'>
          <div className='content-header'>
            <Link to={requestRoute}>
              <img className='back-button' src={backIcon} alt='Back Button' />
            </Link>
            <div className='content-title-airport'>
              <span className='title'>Complete</span>
              <span className='description'>Your Request</span>
            </div>
            <Link to={`/${homeRoute}`}>
              <img className='home-button' src={homeIcon} alt='Home Button' />
            </Link>
          </div>
          <div className='content-body' style={{ justifyContent: 'space-between' }}>
            {requestType.airportRequest && <AirportRequestMessage setMessage={setMessage} />}
            {requestType.arrivalRequest && <ArrivalRequestMessage setMessage={setMessage} />}
            {requestType.dentalRequest && <DentalRequestMessage setMessage={setMessage} />}
            {requestType.homeCleaningRequest && (
              <HomeCleaningRequestMessage setMessage={setMessage} />
            )}
            <div className='send-buttons-container'>
              <span className='title'>Send Request With</span>
              <ButtonWithIcon
                title='WhatsApp'
                iconSrc={whatsappIcon}
                sendMessage={() => whatsappActions(message)}
              />
              <ButtonWithIcon
                title='Telegram'
                iconSrc={telegramIcon}
                sendMessage={() => telegramActions(message)}
                className='telegram-button'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendRequest;
