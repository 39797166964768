const Info = ({ title, appName }) => {
  return (
    <div className='info-container'>
      <div className='title'>{title}</div>
      <div className='app'>{appName}</div>
    </div>
  );
};

export default Info;
