import React, { useState } from 'react';
import Header from '../../components/header';
import dentalCareIcon from '../../assets/dental/dental-care.png';
import Warning from '../../components/warning';
import NextPageButton from '../../components/nextPageButton';
import 'react-slideshow-image/dist/styles.css';
import ClinicInfo from './ClinicInfo';
import LocationInfo from './LocationInfo';

const DentalClinic = () => {
  const [activeMap, setActiveMap] = useState(false);
  const home = JSON.parse(localStorage.getItem('Home'));

  if (!home) {
    return <Warning></Warning>;
  }

  return (
    <div className='main-container'>
      <Header title='Dental Care' iconSrc={dentalCareIcon} />

      <div className='content'>
        <div className='content-container bg-dental'>
          {activeMap ? (
            <LocationInfo setActiveMap={setActiveMap} />
          ) : (
            <ClinicInfo setActiveMap={setActiveMap} />
          )}
        </div>
      </div>

      <NextPageButton buttonText='Go to Form' bgColor='#FF4954' routeUrl={'/dental-form'} />
    </div>
  );
};

export default DentalClinic;
